import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'card', loadChildren: './card/card.module#CardPageModule' },
  { path: 'date-time', loadChildren: './date-time/date-time.module#DateTimePageModule' },
  { path: 'avatar', loadChildren: './avatar/avatar.module#AvatarPageModule' },
  { path: 'check', loadChildren: './check/check.module#CheckPageModule' },
  { path: 'grid', loadChildren: './grid/grid.module#GridPageModule' },
  { path: 'input', loadChildren: './input/input.module#InputPageModule' },
  { path: 'slides', loadChildren: './slides/slides.module#SlidesPageModule' },
  { path: 'progress-bar', loadChildren: './progress-bar/progress-bar.module#ProgressBarPageModule' },
  { path: 'alert', loadChildren: './alert/alert.module#AlertPageModule' },
/*  { path: 'popover', loadChildren: './popover/popover.module#PopoverPageModule' },  
  { path: 'pophover', loadChildren: './popover/pophover/pophover.module#PophoverPageModule' }, */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
